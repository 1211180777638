import React from "react";
import {
  ContainerContent,
  ContainerContentFixed,
  ContainerCta,
  ContentTitle,
  InternalLinkTypes,
  Layout,
  SEO,
} from "../../components";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import "./blog.css";
const Blog = () => {
  const data = useStaticQuery(graphql`
    query {
      BlogImage: file(relativePath: { eq: "blogs/blog1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO title={"Landscaping Ideas for Your Newly Built Home"} />
      <ContainerContent style={{ padding: 0 }}>
        <Img
          fluid={data.BlogImage.childImageSharp.fluid}
          style={{ marginBottom: "8px", maxHeight: 500 }}
        />
      </ContainerContent>
      <ContainerContent>
        <ContainerContentFixed>
          <ContentTitle title={"Landscaping Ideas for Your Newly Built Home"} />
          <div className={"cp-blog"}>
            <p>
              {" "}
              <b>
                Who doesn’t wish to have that eye-catching yard just like the
                ones we see on magazine covers. There are various options to
                choose from and for this you need to prepare a mood board
                considering exactly what kind of design you are thinking of
                which should be beautiful yet multi-functional but also keeping
                your budget in mind.{" "}
              </b>
            </p>
            <p>
              Landscaping isn’t that easy, but it’s not that hard either, and to
              create a picture-perfect landscape, hiring a professional
              landscaper is the best option, this will save you both time and
              money.
            </p>

            <p>
              The most attractive landscape designs are the ones which best fits
              your personality and reflects your sense of imagination and
              lifestyle, but before that there are few factors you should
              consider such as:
            </p>

            <p>
              <h4>- Keeping the regional climate in mind</h4>
            </p>

            <p>
              Before planning you should know the topography of the site keeping
              the specific conditions of your yard in mind creating a
              microclimate which usually falls into 4 categories: full sun,
              shade, partial shade, or deep shade. Make sure you take notes of
              how water drains in your landscape.
            </p>

            <p>
              <h4>- Who will be using your yard?</h4>
            </p>

            <p>
              Firstly, you need to determine who will be using your yard. Are
              kids using it. &nbsp;Do you have pets that will remain outside of
              your inner boundaries? Do you want to have a fully groomed yard
              full of your favorite fruits and vegetables? Or you wish to relax
              there? A clear view will help locate everything on it’s right
              position.{" "}
            </p>

            <p>
              <h4>- what about DIY? </h4>
            </p>

            <p>
              You can hire a professional to complete the major earthwork such
              as concreting and paving as they have the right skills and
              machinery for that specific work and the rest is all in your
              hands. You can be your own boss and decide the way everything
              should be. There are many diy projects that you can do on your own
              without much hassle.{" "}
            </p>

            <p>
              <h4>- Choose a theme</h4>
            </p>

            <p>
              By keeping a theme in your mind, it gets easy for you to select
              your plants and required materials. Themes can be created by using
              consistent shapes or forms throughout your yard or creating an
              oriental garden if you choose relaxation as your first priority.
            </p>

            <p>
              <h4>- Which plants would look the best?</h4>
            </p>

            <p>
              When selecting plants for your landscape the best advice can be
              found at your local nursery. The staff working there can guide you
              the best according to your taste and requirements as they are
              experienced and has the most valuable information.
            </p>

            <p>
              <h4>- get some inspiration</h4>
            </p>

            <p>
              With the ease of internet we have access to more ideas than
              before. Nowadays social media is the answer to every question, one
              search and hundreds of options are available. You can easily
              search and get amazed by how stunning landscapes have been
              created. You can choose any image and make the same like that or
              choose few images and turn something new.{" "}
            </p>

            <p>
              <h4>- What’s on trend?</h4>
            </p>

            <p>
              As the world is moving fast, day by day people are getting really
              busy, they have almost no time to maintain their garden, and so in
              today’s society we are trending towards low maintenance
              landscapes. Architectural plants covered with pebbles all around
              with an area of artificial grass for the kids to play are becoming
              increasingly popular.{" "}
            </p>

            <p>
              <b>
                Below is a list of all the landscaping styles to help you choose
                the best for you.&nbsp;
              </b>
            </p>

            <p>
              <h4>FORMAL</h4>
            </p>

            <p>
              A formal garden is a garden with clear structure, geometric shapes
              and in most cases a symmetrical layout. As formal gardens reply on
              symmetry, one garden will match another and in a small space
              symmetry becomes the focal point. This is what the eye registers,
              it recognizes the pattern and takes everything in as a whole.
            </p>

            <p>
              <h4>INFORMAL</h4>
            </p>

            <p>
              Informal gardens are more popular, they follow the natural terrain
              by using curved lines. The human eye follows curves easier than
              straight lines.Balance is created not through symmetry (as in a
              formal garden) but with plant material characteristics such as
              plant shape, colour, size and texture.
            </p>

            <p>
              <h4>TRADITIONAL</h4>
            </p>

            <p>
              Imagine yourself transported to 17th century France and strolling
              down the garden paths at Versailles. Traditional gardens like this
              one were originally all about appearance. Kings and Queens wanted
              to display their wealth and power to their subjects as well as
              leaders of other countries. Such gardens were filled with statues,
              elaborate fountains and highly manicured plants.
            </p>

            <p>
              <h4>CAPE COD</h4>
            </p>

            <p>
              In Cape Cod the homes are meant to blend into the landscape,
              however, still stand out with their charming character. The
              original Cape Cod home was built to endure harsh weather
              conditions; carefully placed within their surroundings, they are
              simple and elegant. The homes are usually painted in natural tones
              to reflect the light landscape, their design is not overpowering
              to the natural beauty of the area
            </p>

            <p>
              <h4>COASTAL</h4>
            </p>

            <p>
              Coastal gardens are one of the few that keep their surroundings as
              the main priority in their design. Coastal landscapes are simple,
              they flow and their design speaks volumes by enhancing the natural
              beauty of their location. Coastal gardens vary by area, whether
              you live on the east or west coast, on or off the water; they
              always represent a relaxing simple lifestyle.{" "}
            </p>

            <p>
              <h4>SPANISH</h4>
            </p>

            <p>
              Spanish garden is now popular all over the world, especially in
              areas with a similar hot, dry climate. The Spanish combined the
              powerful elements of their influences and perfected the principle
              of separating spaces or rooms within the garden layout. Walled
              sections create intimate patio areas, courtyards house impressive
              fountains and terraces offering views into the landscape are all
              incorporated into the Spanish garden style.
            </p>

            <p>
              <h4>SOUTHWEST</h4>
            </p>

            <p>
              The Southwest garden represents the dry arid regions of the west.
              However, you don’t have to live in those regions to admirer and
              implement their beauty into your own yard. The southwest style is
              known for its Native American and Spanish details that contribute
              to the overall simple, functional and low maintenance landscape.
              The southwest design includes native plants, and incorporates
              bright, colorful building materials.
            </p>

            <p>
              <h4>MEDITERRANEAN </h4>
            </p>

            <p>
              Mediterranean gardens are best known for their casual elegance.
              This style of garden combines relaxed materials and plants with
              formal accents and designs. Terra cotta pots, tiered fountains,
              statuary, Roman columns and bocce ball courts are all hallmarks of
              Mediterranean gardens. The plant palette of a Mediterranean
              landscape is made of plants that provide texture, color and
              structure – think lavender, cypress trees and ornamental grasses.{" "}
            </p>

            <p>
              <h4>COLONIAL</h4>
            </p>

            <p>
              The Colonial design was introduced on the fundamental principles
              of survival. These gardens were used for cooking, baking and
              keeping away insects; with very few plantings for ornamental
              purposes. Today the style has combined the classic functional
              design with a loose combination of pastel colored flowers. The
              Colonial style is ideal for homes built with a colonial
              architecture or for homeowners wanting a functional, more formal
              landscape.
            </p>

            <p>
              <h4>FRENCH</h4>
            </p>

            <p>
              &nbsp;The French garden style adopted many principles from the
              renaissance gardens but incorporated a style of their own. The
              impact of the renaissance did not hit the gardens of France until
              the 1600’s, soon after the chateaus’ gardens were designed using
              principles that were completely new to the region. The main goal
              for this style was to make an extravagant impression to all of the
              guests who visited the country homes of the wealthy royals.
            </p>

            <p>
              <h4>DESERT</h4>
            </p>

            <p>
              If you are one of the people who think of the term “desert garden”
              as an oxymoron, it’s time to reconsider and push the images of
              barren sand dunes out of your mind. Beautiful gardens can exist in
              the desert, and many desert gardens are incredibly vibrant and
              full of plant life. The key to successful desert landscaping are
              knowledge and planning.{" "}
            </p>

            <p>
              <h4>TROPICAL</h4>
            </p>

            <p>
              The most recognizable trademarks of this style are lush, tropical
              plants and bold colors. Think palm trees swaying in the wind,
              birds of paradise showing off their bright flowers and lots of
              healthy greenery. Hammocks are especially appropriate for a
              tropical garden, just make sure the trees you hang it from are
              nice and h4. Many tropical style yards feature a swimming pool.
              These pools usually have a natural look as if you could stumble
              upon them in the rainforest or jungle.
            </p>

            <p>
              <h4>COUNTRY</h4>
            </p>

            <p>
              The Country style garden originates from the renowned English
              gardens of the 1600s. Adapting to many changes throughout the
              centuries, this garden style has become ideal for enhancing
              farmhouse, Victorian, ranch and rustic architecture. These lush
              informal landscapes were originally designed for practicality.
              Farm animals, beehives, fruit trees, vegetables and herbs were the
              main elements while flowers were used as filler.
            </p>

            <p>
              <h4>ENGLISH</h4>
            </p>

            <p>
              The English landscape style is the known influence that shifted
              from formal, symmetrical gardens to a looser, irregular style.
              Before the introduction of English landscape, nature was views as
              dangerous, the English landscape completely changed this view to
              appreciate and value the natural world. The innovative design of
              the English landscape style forever changed gardening and
              influences many homeowners to bring a sense of nature into their
              own yard to this day.
            </p>

            <p>
              <h4>XERISCAPE</h4>
            </p>

            <p>
              At its simplest, xeriscaping is about selecting plants that can
              thrive in the landscape with as little supplemental water as
              possible. This means choosing a variety of native plants, as well
              as other well-adapted species. However, though xeriscaping is
              about low-water landscaping, people who are interested in this
              style of landscaping often look at other ways of having a smaller
              footprint on the planet.
            </p>

            <p>
              <h4>TUSCAN </h4>
            </p>

            <p>
              The gardens throughout Tuscany have been recognized for centuries
              for their impressive design. From the Medici gardens to small
              villas within the rolling Tuscan hills, the landscapes tell the
              story of their past. These gardens have inspired us for centuries,
              starting with the renaissance gardens that have transformed into
              their own identity throughout the Tuscan region of Italy.
            </p>

            <p>
              <h4>WOODLAND</h4>
            </p>

            <p>
              Reflecting the natural way that fauna grows in a wooded area, the
              Woodland Style has a less manicured appearance than many of the
              other garden styles. This would be a good choice if you do not
              wish to spend a lot of time on maintenance.
            </p>

            <p>
              <h4>BUTTERFLY</h4>
            </p>

            <p>
              Dozens of butterfly or bird species common to your area make a
              welcome addition to many gardens. Certain species of plants
              provide a food source and are useful for attracting these flying
              friends. Create areas that are open yet protected from the wind.
            </p>

            <p>
              <h4>ORGANIC</h4>
            </p>

            <p>
              As more people learn about the harmful effects of many chemicals,
              growing plants organically has become more popular. That means
              rather than chemical fertilizers or sprays, practicing natural
              methods of pest control and fertilization.
            </p>

            <p>
              <h4>MODERN </h4>
            </p>

            <p>
              Modern landscaping is known for its streamlined aesthetic and
              sleek sophisticated style. Overall the garden will feel controlled
              and organized. Typically, the focus is heavier on hardscape and
              structures than it is on plants. Modern plants are usually green
              and selected for shape and texture. Pops of color are then added
              with furniture cushions, planters or a painted wall.
            </p>

            <p>
              <h4>JAPANESE </h4>
            </p>

            <p>
              Traditional Japanese gardens are designed for peaceful
              contemplation. The primary focus of an Oriental garden is nature.
              The elements of a Japanese garden mimic or symbolize natural
              elements. Thus, geometric shapes and artificial stone are not
              common in Asian landscape design. There are four essential
              elements used in Japanese garden design: rocks, water, plants, and
              ornaments.{" "}
            </p>

            <p>
              <h4>COTTAGE</h4>
            </p>

            <p>
              Is considered the old-style gardens.&nbsp; Consider using magnolia
              trees, hydrangeas, roses, and lilacs. The flowering perennials we
              mentioned will fit well in this look.&nbsp; Herbs are also great
              in a Cottage garden sweet bay, rosemary, blueberries, and figs
              will match perfectly with this style of garden.
            </p>

            <p>
              <h4>ORIENTAL</h4>
              It is often the kind of garden found in small backyards. It uses
              rocks and water features. A wide variety of plants will create
              several interesting angles with this style. &nbsp;This type of
              garden requires lots of maintenance and care plus water usage is
              high.&nbsp;Lots of junipers, topiary, and evergreen plants
              including bonsai.
            </p>
          </div>
        </ContainerContentFixed>
      </ContainerContent>
      <section>
        <ContainerCta
          title={"Your future happiness is waiting"}
          subTitle={"Start building your custom Grandeur Home"}
          primaryLink={InternalLinkTypes.GetStarted}
          primaryText={"Get Started"}
          secondaryLink={InternalLinkTypes.Blogs}
          secondaryText={"Read other blogs"}
        />
      </section>
    </Layout>
  );
};

export default Blog;
